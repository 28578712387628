import { GA_TRACKING_ID } from './config';
import { getUtmParams } from './utm';
import { debugGA } from './debug';

export function initializeGtag() {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function() {
    window.dataLayer.push(arguments);
  };
  
  window.gtag('js', new Date());
  window.gtag('config', GA_TRACKING_ID, {
    send_page_view: false,
    ...getUtmParams()
  });
}

export function pageview(url) {
  if (!window.gtag) return;
  
  window.gtag('event', 'page_view', {
    page_path: url,
    page_location: window.location.href,
    page_title: document.title,
    ...getUtmParams()
  });
}

export function trackEvent({ action, category, label, value }) {
  if (!window.gtag) return;

  debugGA.log('Tracking event', { action, category, label, value });

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
    ...getUtmParams(),
    non_interaction: false
  });
}

export const event = trackEvent;