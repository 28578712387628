// Constants for power calculations
export const POWER_CONSTANTS = {
  VOLTAGE: 240, // Standard home voltage
  HIGH_INRUSH_THRESHOLD: 1000, // Watts threshold for high starting current
  INRUSH_DURATION: 0.5, // Duration in seconds for starting current
  SAFETY_FACTOR: 1.2 // 20% safety margin for calculations
};

// Format power values for display
export const formatPowerValue = {
  amps: (value) => `${Number(value).toFixed(1)}A`,
  watts: (value) => `${Number(value).toLocaleString()}W`,
  kw: (value) => `${Number(value).toFixed(1)} kW`,
  kwh: (value) => `${Number(value).toFixed(1)} kWh`
};

/**
 * Calculate total power requirements including inrush current considerations
 */
export function calculatePowerNeeds(appliances) {
  const baseCalculations = calculateBasePower(appliances);
  const inrushImpact = calculateInrushImpact(appliances);
  const totalRequirements = combinePowerRequirements(baseCalculations, inrushImpact);

  return {
    totalAmps: Number(totalRequirements.totalAmps.toFixed(1)),
    continuousPowerKw: Number((totalRequirements.continuousWatts / 1000).toFixed(1)),
    peakPowerKw: Number((totalRequirements.peakWatts / 1000).toFixed(1)),
    dailyKwh: Number((totalRequirements.dailyWattHours / 1000).toFixed(1)),
    hasHighStartingLoads: inrushImpact.hasHighInrushLoads,
    highStartingCount: inrushImpact.highInrushCount
  };
}

/**
 * Check if an appliance has high starting current
 */
export function hasHighStartingCurrent(appliance) {
  return (appliance.startWatts - appliance.avgWatts) >= POWER_CONSTANTS.HIGH_INRUSH_THRESHOLD;
}

// Private helper functions
function calculateBasePower(appliances) {
  return appliances.reduce((acc, appliance) => {
    const quantity = appliance.quantity || 0;
    const continuousWatts = appliance.avgWatts * quantity;
    const dailyWattHours = continuousWatts * appliance.hoursPerDay;

    return {
      continuousWatts: acc.continuousWatts + continuousWatts,
      dailyWattHours: acc.dailyWattHours + dailyWattHours
    };
  }, { continuousWatts: 0, dailyWattHours: 0 });
}

function calculateInrushImpact(appliances) {
  let totalWatts = 0;
  let highInrushCount = 0;

  appliances.forEach(app => {
    const quantity = app.quantity || 0;
    if (hasHighStartingCurrent(app)) {
      // For high inrush appliances, use starting watts
      totalWatts += app.startWatts * quantity;
      highInrushCount++;
    } else {
      // For regular appliances, use average watts
      totalWatts += app.avgWatts * quantity;
    }
  });

  return {
    totalWatts,
    peakAmps: totalWatts / POWER_CONSTANTS.VOLTAGE,
    hasHighInrushLoads: highInrushCount > 0,
    highInrushCount
  };
}

function combinePowerRequirements(base, inrush) {
  // Calculate total amperage including both regular and inrush loads
  const totalAmps = inrush.peakAmps;
  
  // Apply safety factor to continuous power
  const effectiveWatts = base.continuousWatts * POWER_CONSTANTS.SAFETY_FACTOR;

  return {
    continuousWatts: base.continuousWatts,
    peakWatts: inrush.totalWatts,
    totalAmps,
    dailyWattHours: base.dailyWattHours
  };
}