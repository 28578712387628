import { mainNav } from './mainNav';
import { footerNav } from './footerNav';
import { solutions } from './solutions';
import { products } from './products';
import { resources } from './resources';
import { partnerships } from './partnerships';
import { additionalLinks } from './additionalLinks';

// Export individual navigation sections
export {
  mainNav,
  footerNav,
  solutions,
  products,
  resources,
  partnerships,
  additionalLinks
};

// Export combined navigation object
export const navigation = {
  main: mainNav,
  footer: footerNav,
  solutions,
  products,
  resources,
  partnerships,
  additionalLinks
};