import { HomeIcon, CalculatorIcon, BookOpenIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

export const additionalLinks = {
  solutions: [],
  products: [
    { name: 'View All Products', href: '/products', icon: HomeIcon },
    { name: 'Size Your System', href: '#calculator', icon: CalculatorIcon, isCalculator: true }
  ],
  resources: [
    { name: 'View All Resources', href: '/resources', icon: BookOpenIcon },
    { name: 'Download Guides', href: '/resources/guides', icon: DocumentTextIcon }
  ]
};