import React from 'react';
import { Link } from 'react-router-dom';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { usePhoneCall } from '../hooks/usePhoneCall';
import { navigation } from '../utils/navigation';
import { FacebookIcon, LinkedInIcon } from './icons/SocialIcons';

export default function Footer() {
  const handlePhoneCall = usePhoneCall();
  
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          {/* Logo and contact info */}
          <div className="space-y-8">
            <Link to="/" className="flex items-center">
              <img
                src="https://cdn.prod.website-files.com/67091f695cd8e90971357ea4/671b89182e8f151846ae4e4e_Agilio%20Logo-1.webp"
                alt="Agilio"
                className="h-8 w-auto"
              />
            </Link>
            <p className="text-sm leading-6 text-gray-600">
              Your trusted partner in home improvement.
            </p>
            <div className="space-y-4">
              <a
                href="tel:+18885146160"
                className="text-sm leading-6 text-gray-600 hover:text-gray-900 flex items-center gap-x-2"
                onClick={() => handlePhoneCall('footer')}
              >
                <PhoneIcon className="h-5 w-5" />
                +1 888-514-6160
              </a>
              <a
                href="mailto:support@agilio.pro"
                className="text-sm leading-6 text-gray-600 hover:text-gray-900 flex items-center gap-x-2"
              >
                <EnvelopeIcon className="h-5 w-5" />
                support@agilio.pro
              </a>
            </div>
          </div>

          {/* Navigation links */}
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {/* Solutions */}
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Solutions</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Products */}
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Products</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.products.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Resources and Legal */}
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Resources</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Legal</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.footer.legal.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Footer bottom */}
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <div className="flex items-center justify-between">
            <p className="text-xs leading-5 text-gray-500">
              &copy; {new Date().getFullYear()} Agilio. All rights reserved.
            </p>
            <div className="flex space-x-6">
              {navigation.footer.social.map((item) => {
                const Icon = item.name === 'Facebook' ? FacebookIcon : LinkedInIcon;
                return (
                  <a
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">{item.name}</span>
                    <Icon className="h-6 w-6" />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}