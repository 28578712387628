import { POWER_CONSTANTS } from '../powerCalculations';
import { BATTERY_SPECS } from './specs';

export function calculateSystemSize(powerNeeds) {
  const { totalAmps, peakPowerKw, dailyKwh } = powerNeeds;

  // Calculate required inverters based on total amperage (includes both regular and inrush loads)
  const requiredInverters = Math.max(
    Math.min(Math.ceil(totalAmps / BATTERY_SPECS.inverterAmps), BATTERY_SPECS.maxInverters),
    1
  );

  // Store daily kWh for backup time calculations
  return {
    inverterCount: requiredInverters,
    dailyKwh: dailyKwh
  };
}