import React from 'react';
import { trackButtonClick } from '../../utils/analytics/buttonTracking';
import { BUTTON_CATEGORIES } from '../../utils/analytics/buttonTracking';

export default function GetProtectedButton({ location = 'unknown', className = '' }) {
  const handleClick = () => {
    trackButtonClick('Get Protected', BUTTON_CATEGORIES.LEAD, location);
    window.open('https://agilio.go-kf.com/contact-us', '_blank', 'noopener noreferrer');
  };

  return (
    <button
      onClick={handleClick}
      className={`rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ${className}`}
    >
      Get Protected
    </button>
  );
}