import React from 'react';
import { CalculatorIcon } from '@heroicons/react/24/outline';
import { trackButtonClick } from '../../utils/analytics/buttonTracking';
import { BUTTON_CATEGORIES } from '../../utils/analytics/buttonTracking';
import BatteryCalculator from '../calculator/BatteryCalculator';

export default function HeroCalculatorButton({ location = 'unknown', className = '' }) {
  const [isCalculatorOpen, setIsCalculatorOpen] = React.useState(false);

  const handleClick = () => {
    trackButtonClick('Size Your System', BUTTON_CATEGORIES.CALCULATOR, location);
    setIsCalculatorOpen(true);
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={`inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white ${className}`}
      >
        <CalculatorIcon className="h-5 w-5" />
        Size Your System
      </button>

      <BatteryCalculator 
        isOpen={isCalculatorOpen}
        onClose={() => setIsCalculatorOpen(false)}
      />
    </>
  );
}