// Button click categories
export const BUTTON_CATEGORIES = {
  LEAD: 'Lead Generation',
  NAVIGATION: 'Navigation',
  CALCULATOR: 'Calculator',
  CONTACT: 'Contact',
  DOWNLOAD: 'Download'
};

export function trackButtonClick(name, category, location) {
  if (window.gtag) {
    window.gtag('event', 'button_click', {
      event_category: category,
      event_label: name,
      button_location: location
    });
  }
}