/**
 * Enriches HubSpot form context with additional data
 */
export function enrichHubSpotContext(form) {
  // Add UTM parameters
  const searchParams = new URLSearchParams(window.location.search);
  const utmParams = {
    utm_source: searchParams.get('utm_source'),
    utm_medium: searchParams.get('utm_medium'), 
    utm_campaign: searchParams.get('utm_campaign'),
    utm_term: searchParams.get('utm_term'),
    utm_content: searchParams.get('utm_content')
  };

  // Add page context
  const pageContext = {
    page_url: window.location.href,
    page_name: document.title,
    referrer: document.referrer,
    user_agent: navigator.userAgent,
    viewport_size: `${window.innerWidth}x${window.innerHeight}`,
    screen_resolution: `${window.screen.width}x${window.screen.height}`,
    browser_language: navigator.language,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  // Add to HubSpot context
  const context = {
    ...utmParams,
    ...pageContext,
    form_source: 'Website',
    submission_timestamp: new Date().toISOString()
  };

  // Filter out undefined/null values
  const cleanContext = Object.fromEntries(
    Object.entries(context).filter(([_, v]) => v != null)
  );

  // Add context data to form
  if (form && typeof form.setContext === 'function') {
    form.setContext(cleanContext);
  }
}