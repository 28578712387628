import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GA_TRACKING_ID } from '../../utils/analytics/config';
import { initializeGtag, pageview } from '../../utils/analytics/gtag';

export default function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.async = true;
    
    script.onload = () => {
      initializeGtag();
      // Track initial page view
      pageview(location.pathname + location.search);
    };

    document.head.appendChild(script);

    return () => {
      const existingScript = document.querySelector(`script[src*="googletagmanager.com/gtag"]`);
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);

  // Track page views on route changes
  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);

  return null;
}