import { trackEvent } from '../gtag';
import { EVENT_CATEGORIES, EVENT_ACTIONS } from './constants';
import { debugGA } from '../debug';

export function trackPhoneCall(source) {
  debugGA.log('Tracking phone call', { source });
  trackEvent({
    action: EVENT_ACTIONS.CLICK,
    category: EVENT_CATEGORIES.LEAD,
    label: `phone_call_${source}`
  });
}

export function trackButtonClick(buttonName, location) {
  debugGA.log('Tracking button click', { buttonName, location });
  trackEvent({
    action: EVENT_ACTIONS.CLICK,
    category: EVENT_CATEGORIES.ENGAGEMENT,
    label: `${buttonName}_${location}`
  });
}

export function trackLeadFormOpen(source) {
  debugGA.log('Tracking lead form open', { source });
  trackEvent({
    action: EVENT_ACTIONS.OPEN,
    category: EVENT_CATEGORIES.LEAD,
    label: `lead_form_${source}`
  });
}

export function trackCalculatorOpen(source) {
  debugGA.log('Tracking calculator open', { source });
  trackEvent({
    action: EVENT_ACTIONS.OPEN,
    category: EVENT_CATEGORIES.CALCULATOR,
    label: `calculator_${source}`
  });
}

export function trackGetStarted(source) {
  debugGA.log('Tracking get started', { source });
  trackEvent({
    action: EVENT_ACTIONS.CLICK,
    category: EVENT_CATEGORIES.LEAD,
    label: `get_started_${source}`
  });
}