export const EVENT_CATEGORIES = {
  LEAD: 'Lead Generation',
  CALCULATOR: 'Calculator',
  NAVIGATION: 'Navigation',
  ENGAGEMENT: 'Engagement',
  DOWNLOAD: 'Download'
};

export const EVENT_ACTIONS = {
  CLICK: 'click',
  OPEN: 'open',
  SUBMIT: 'submit',
  DOWNLOAD: 'download'
};