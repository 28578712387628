import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { usePhoneCall } from '../hooks/usePhoneCall';
import MegaMenu from './navigation/MegaMenu';
import MobileNavigation from './navigation/MobileNavigation';
import Logo from './ui/Logo';

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handlePhoneCall = usePhoneCall();

  return (
    <header className="fixed inset-x-0 top-0 z-50 bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Logo />
        </div>
        
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div className="hidden lg:flex lg:gap-x-12">
          <MegaMenu />
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:gap-x-8">
          <a
            href="tel:+18885146160"
            className="text-sm font-semibold leading-6 text-gray-900 hover:text-green-600 flex items-center gap-x-2"
            onClick={() => handlePhoneCall('navbar')}
          >
            <PhoneIcon className="h-5 w-5" />
            +1 888-514-6160
          </a>
        </div>
      </nav>

      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Logo />
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <MobileNavigation onClose={() => setMobileMenuOpen(false)} />
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}