export const debugGA = {
  log: (message, data) => {
    if (process.env.NODE_ENV === 'development') {
      console.group(`🔍 GA Debug: ${message}`);
      if (data) console.log('Data:', data);
      console.log('URL:', window.location.href);
      console.log('Referrer:', document.referrer);
      console.groupEnd();
    }
  },
  
  error: (message, error) => {
    console.error(`❌ GA Error: ${message}`, error);
  }
};