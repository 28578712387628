export const footerNav = {
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/profile.php?id=61567797987805',
      icon: 'facebook'
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/agiliopro',
      icon: 'linkedin'
    }
  ],
  legal: [
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Terms of Service', href: '/terms-of-service' },
    { name: 'Data Removal', href: '/data-removal' }
  ]
};