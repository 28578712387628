import React from 'react';
import { CalculatorIcon } from '@heroicons/react/24/outline';
import { trackButtonClick } from '../../utils/analytics/buttonTracking';
import { BUTTON_CATEGORIES } from '../../utils/analytics/buttonTracking';
import BatteryCalculator from '../calculator/BatteryCalculator';

export default function CalculatorButton({ location = 'unknown', className = '' }) {
  const [isCalculatorOpen, setIsCalculatorOpen] = React.useState(false);

  const handleClick = () => {
    trackButtonClick('Size Your System', BUTTON_CATEGORIES.CALCULATOR, location);
    setIsCalculatorOpen(true);
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={`inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm bg-green-600 text-white hover:bg-green-500 ${className}`}
      >
        <CalculatorIcon className="h-5 w-5" />
        Size Your System
      </button>

      <BatteryCalculator 
        isOpen={isCalculatorOpen}
        onClose={() => setIsCalculatorOpen(false)}
      />
    </>
  );
}