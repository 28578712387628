import { debugGA } from './debug';

export function getUtmParams() {
  try {
    // First check URL parameters
    const params = new URLSearchParams(window.location.search);
    const utmParams = {};
    
    ['source', 'medium', 'campaign', 'term', 'content'].forEach(param => {
      const value = params.get(`utm_${param}`);
      if (value) {
        utmParams[`campaign_${param}`] = value;
      }
    });

    // If we have UTM params, return them
    if (Object.keys(utmParams).length > 0) {
      debugGA.log('Found UTM params:', utmParams);
      return utmParams;
    }

    // Check referrer if no UTM params
    if (document.referrer) {
      try {
        const referrerUrl = new URL(document.referrer);
        // Don't count internal navigation
        if (referrerUrl.hostname !== window.location.hostname) {
          const referrerParams = {
            campaign_source: referrerUrl.hostname,
            campaign_medium: 'referral'
          };
          debugGA.log('Using referrer params:', referrerParams);
          return referrerParams;
        }
      } catch (error) {
        debugGA.error('Error parsing referrer:', error);
      }
    }

    // Default to direct traffic
    const defaultParams = {
      campaign_source: '(direct)',
      campaign_medium: '(none)'
    };
    debugGA.log('Using default params:', defaultParams);
    return defaultParams;

  } catch (error) {
    debugGA.error('Error getting UTM params:', error);
    return {
      campaign_source: '(direct)',
      campaign_medium: '(none)'
    };
  }
}