import React from 'react';
import { Link } from 'react-router-dom';
import { useGetStarted } from '../hooks/useGetStarted';

export default function CTASection() {
  const handleGetStarted = useGetStarted();

  return (
    <div className="bg-green-600">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Ready to transform your home?
            <br />
            Let's make it happen, together.
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-green-100">
            Join thousands of happy homeowners who've discovered the simplicity of working with Agilio. One call connects you with everything you need for a successful home improvement project.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <button
              onClick={() => handleGetStarted('cta_section')}
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-50"
            >
              Get Started
            </button>
            <Link to="/how-it-works" className="text-sm font-semibold leading-6 text-white">
              Learn More <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}