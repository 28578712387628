import React, { useEffect } from 'react';
import { enrichHubSpotContext } from '../../utils/hubspot/context';

export default function HubSpotForm({ formId, region = 'na1', portalId = '42269009' }) {
  useEffect(() => {
    // Load HubSpot Forms v2 script
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.async = true;
    
    script.addEventListener('load', () => {
      // Create form after script loads
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: `#hubspot-form-${formId}`,
          region: region,
          onFormReady: (form) => {
            // Add context data when form loads
            enrichHubSpotContext(form);
          },
          onFormSubmit: (form) => {
            // Track form submission
            if (window.gtag) {
              window.gtag('event', 'form_submit', {
                event_category: 'Lead Generation',
                event_label: `HubSpot Form ${formId}`,
                form_location: window.location.pathname
              });
            }
          }
        });
      }
    });

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      const formContainer = document.querySelector(`#hubspot-form-${formId}`);
      if (formContainer) {
        formContainer.innerHTML = '';
      }
    };
  }, [formId, portalId, region]);

  return (
    <div id={`hubspot-form-${formId}`} className="hubspot-form-container p-8" />
  );
}